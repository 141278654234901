import * as Sentry from "@sentry/browser";

////////////////////////////////////////////////////////////////////////////////
//                                  Types
////////////////////////////////////////////////////////////////////////////////

enum Environment {
	DEVELOPMENT = "development",
	PRODUCTION = "production",
}

enum EventType {
	LAND = "land",
	CLICK = "click",
	NEXT = "next",
}

enum PageId {
	REGISER = 0
}

interface JobsRequest {
	firstName: string;
	lastName?: string | null;
	email: string;
	keyword: string;
	zipCode: string;
	event: EventType;
	eventProviderId?: string;
	eventJobTitle?: string;
	loadMoreClicked: number;
}

interface Job {
	title: string;
	date: string;
	clickUrl: string;
	logoUrl: string;
	company: string;
	city: string[];
	description: string;
	providerId: string;
}

interface JobsResponse {
	jobs: Job[];
}

type FBQ = (command: string, id: string) => void;

////////////////////////////////////////////////////////////////////////////////
//                                 Globals
////////////////////////////////////////////////////////////////////////////////

const environment = import.meta.env.PROD
	? Environment.PRODUCTION
	: Environment.DEVELOPMENT;

const numApplicationsForLead = 1;
const numApplicationsForSubmitApplication = 3;

const apiUrl =
	environment == Environment.PRODUCTION
		? "https://project-zebra-api-fh8yf.ondigitalocean.app"
		: "http://localhost:8080";

const pageLanding = document.querySelector("#page__landing")! as HTMLElement;
const pageLandingButton = pageLanding.querySelector("button")! as HTMLButtonElement;

const pageRegister = document.querySelector("#page__register")! as HTMLElement;
const pageRegisterForm = pageRegister.querySelector("form")! as HTMLFormElement;
const pageRegisterError = pageRegister.querySelector(".error")! as HTMLParagraphElement;
const pageRegisterFirstNameInput = pageRegisterForm.querySelector('[name="firstName"]')! as HTMLInputElement;
const pageRegisterLastNameInput = pageRegisterForm.querySelector('[name="lastName"]')! as HTMLInputElement;
const pageRegisterEmailInput = pageRegisterForm.querySelector('[name="email"]')! as HTMLInputElement;

const pageJobType = document.querySelector("#page__job-type")! as HTMLElement;
const pageJobTypeH1 = pageJobType.querySelector("h1")! as HTMLElement;
const pageJobTypeForm = pageJobType.querySelector("form")! as HTMLFormElement;
const pageJobZipCodeInput = pageJobTypeForm.querySelector("input.zip-code")! as HTMLInputElement;
const pageJobTypeError = pageJobType.querySelector(".error")! as HTMLParagraphElement;
const pageJobTypeKeywordInput = pageJobTypeForm.querySelector('[name="keyword"]')! as HTMLInputElement;
const pageJobTypeZipCodeInput = pageJobTypeForm.querySelector('[name="zipCode"]')! as HTMLInputElement;

const pageLoading = document.querySelector("#page__loading")! as HTMLElement;

const pageJobHeaderOptions = [
	"Check out this {keyword} opportunity:",
	"Explore this {keyword} position:",
	"Here's a {keyword} role you might like:",
	"Consider this {keyword} job opening:",
	"Discover this {keyword} career opportunity:",
	"A new {keyword} job for you:",
	"Take a look at this {keyword} job:",
	"We found a {keyword} job for you:",
	"This {keyword} job might interest you:",
	"Interested in a {keyword} position? Check this out:",
]
let pageJobHeaderIndex = Math.floor(Math.random() * pageJobHeaderOptions.length);
const pageJob = document.querySelector("#page__job")! as HTMLElement;
const pageJobCompany = pageJob.querySelector(".job__company")! as HTMLElement;
const pageJobHeader = pageJob.querySelector(".job__header")! as HTMLElement;
const pageJobLogoImg = pageJob.querySelector("img.job__logo")! as HTMLImageElement;
const pageJobTitle = pageJob.querySelector(".job__title")! as HTMLElement;
const pageJobDescription = pageJob.querySelector(".job__description")! as HTMLElement;
const pageJobLearnMoreButton = pageJob.querySelector("button.job__learn-more")! as HTMLButtonElement;
const pageJobSkipButton = pageJob.querySelector("button.job__skip")! as HTMLButtonElement;
const pageJobBackLink = pageJob.querySelector("a.job__back")! as HTMLAnchorElement;

const pageListJobs = document.querySelector("#page__list-jobs")! as HTMLElement;
const pageListJobsJobList = pageListJobs.querySelector('#job-list')! as HTMLElement;
const pageListJobsJobTemplate = pageListJobs.querySelector('#job-list-card')! as HTMLElement;
const pageListJobsLoadMoreButton = pageListJobs.querySelector('#job-list__load-more')! as HTMLButtonElement;
const pageListJobsBackLink = pageListJobs.querySelector("a.job__back")! as HTMLAnchorElement;

const urlParams = new URLSearchParams(window.location.search);

const eventRequestInfo: RequestInit = {
	method: "POST",
	headers: {
		"Content-Type": "application/json",
		"Origin": window.location.origin,
	}
};

const jobsRequestInfo: RequestInit = {
	method: "GET",
	headers: {
		"Accept": "application/json",
		"Origin": window.location.origin,
	},
};

let searchData: Omit<JobsRequest, "event" | "loadMoreClicked"> = {
	firstName: "",
	lastName: "",
	email: "",
	keyword: "",
	zipCode: "",
};

let jobs: Job[] = [];

let loadMoreClicked = 0;

// Hacky way to navigate back to landing or job type page when back button is clicked.
window.history.pushState(null, "", window.location.href);
window.onpopstate = async function () {
	hideAllPages();
	if (searchData.firstName && searchData.email && searchData.keyword && searchData.zipCode) {
		showAndScrollToTop(pageJobType);
	} else {
		showAndScrollToTop(pageLanding);
	}
}

////////////////////////////////////////////////////////////////////////////////
//                               Page Listeners
////////////////////////////////////////////////////////////////////////////////

pageLandingButton.addEventListener("click", () => {
	hide(pageLanding);
	showAndScrollToTop(pageRegister);
});

pageRegisterForm.addEventListener("submit", (event) => {
	event.preventDefault();

	hide(pageRegisterError);

	const formData = new FormData(pageRegisterForm);
	const firstName = (formData.get("firstName") as string).trim();
	const lastName = (formData.get("lastName") as string).trim();
	const email = (formData.get("email") as string).trim();

	if (!firstName) {
		pageRegisterError.innerText = "Please enter your first name.";
		show(pageRegisterError);
		return;
	} else if (!email) {
		pageRegisterError.innerText = "Please enter your email.";
		show(pageRegisterError);
		return;
	}

	searchData = {
		...searchData,
		firstName,
		lastName,
		email,
	};

	hide(pageRegister);
	showAndScrollToTop(pageJobType);
});

pageRegisterFirstNameInput.addEventListener("input", (_event) => {
	hide(pageRegisterError);
});

pageRegisterLastNameInput.addEventListener("input", (_event) => {
	hide(pageRegisterError);
});

pageRegisterEmailInput.addEventListener("input", (_event) => {
	hide(pageRegisterError);
});

pageJobTypeForm.addEventListener("submit", async (event) => {
	event.preventDefault();

	hide(pageJobTypeError);

	const formData = new FormData(pageJobTypeForm);
	const keyword = (formData.get("keyword") as string).trim();
	const zipCode = (formData.get("zipCode") as string).trim();

	if (!keyword) {
		pageJobTypeError.innerText = "Please enter a keyword.";
		show(pageJobTypeError);
		return;
	} else if (!zipCode) {
		pageJobTypeError.innerText = "Please enter a zip code.";
		show(pageJobTypeError);
		return;
	} else if (zipCode.length !== 5) {
		pageJobTypeError.innerText = "Please enter a valid zip code.";
		show(pageJobTypeError);
		return;
	}

	searchData = {
		...searchData,
		keyword,
		zipCode,
	};

	hideAllPages();
	await getJobs(EventType.CLICK, null, null);
	if (jobs.length === 0) {
		showAndScrollToTop(pageJobType);
		show(pageJobTypeError);
		pageJobTypeError.innerText = "No jobs found. Please try another keyword or zip code.";
	} else {
		if (jobs.length === 1) {
			showAndScrollToTop(pageJob);
		} else {
			showAndScrollToTop(pageListJobs);
		}
	}
});

pageJobTypeKeywordInput.addEventListener("input", (_event) => {
	hide(pageJobTypeError);
});

pageJobTypeZipCodeInput.addEventListener("input", (_event) => {
	hide(pageJobTypeError);
});

pageJobLearnMoreButton.addEventListener("click", async () => {
	const providerId = jobs[0]?.providerId;
	const jobTitle = jobs[0]?.title ? `${jobs[0]?.title} :: ((${jobs[0]?.company}))` : null;
	await getJobsAndNavigate(EventType.CLICK, providerId, jobTitle);
});

pageJobSkipButton.addEventListener("click", async () => {
	const providerId = jobs[0]?.providerId;
	const jobTitle = jobs[0]?.title ? `${jobs[0]?.title} :: ((${jobs[0]?.company}))` : null;
	await getJobsAndNavigate(EventType.NEXT, providerId, jobTitle);
});

pageJobZipCodeInput.addEventListener("input", (_event) => {
	if (!/^\d*$/.test(pageJobZipCodeInput.value)) {
		pageJobZipCodeInput.value = pageJobZipCodeInput.value.replace(/\D/g, "");
	}
});

pageJobBackLink.href = window.location.href;

pageListJobsLoadMoreButton.addEventListener('click', async () => {
	loadMoreClicked++;
	const providerId = jobs[0]?.providerId
	const jobTitles = jobs.map(job => `${job.title} :: ((${job.company}))`).join(';');
	await getJobs(EventType.NEXT, providerId, jobTitles);
	scrollToTop();
});

pageListJobsBackLink.href = window.location.href;

////////////////////////////////////////////////////////////////////////////////
//                                 Functions
////////////////////////////////////////////////////////////////////////////////

async function getJobs(event: EventType, eventProviderId: string | null, eventJobTitle: string | null) {
	show(pageLoading);

	const jobsRequest: JobsRequest = {
		firstName: searchData.firstName,
		lastName: searchData.lastName ?? null,
		email: searchData.email,
		keyword: searchData.keyword,
		zipCode: searchData.zipCode,
		event,
		loadMoreClicked,
	};

	if (eventProviderId) {
		jobsRequest.eventProviderId = eventProviderId;
	}
	if (eventJobTitle) {
		jobsRequest.eventJobTitle = eventJobTitle;
	}

	const params = new URLSearchParams(jobsRequest as any).toString();

	const response = await fetch(`${apiUrl}/jobs?${params}`, jobsRequestInfo);
	const data = await response.json();
	if (data.error) {
		console.error(data.error);
		hideAllPages();
		jobs = [];
		showAndScrollToTop(pageJobType);
		show(pageJobTypeError);
		pageJobTypeError.innerText = "No jobs found. Please try another keyword or zip code.";
		return;
	}

	jobs = (data as JobsResponse).jobs;

	if (jobs.length === 1) {
		const job = jobs[0];

		// Go to next header and reset if at end.
		pageJobHeaderIndex++;
		if (pageJobHeaderIndex >= pageJobHeaderOptions.length) {
			pageJobHeaderIndex = 0;
		}

		pageJobHeader.innerText = pageJobHeaderOptions[pageJobHeaderIndex].replace(
			"{keyword}",
			searchData.keyword
		);

		pageJobCompany.innerText = job.company;

		pageJobTitle.innerText = job.title;

		if (job.logoUrl) {
			pageJobLogoImg.src = job.logoUrl;
			pageJobLogoImg.alt = `${job.company} logo`;
			show(pageJobLogoImg);
		} else {
			hide(pageJobLogoImg);
		}

		const description = job.description.replace(/\*/g, '');
		pageJobDescription.innerText = description;
		pageJobDescription.innerHTML += `&nbsp;<a href="${job.clickUrl}" target="_blank">Learn more.</a>`;

		pageJobLearnMoreButton.onclick = async () => {
			window.open(job.clickUrl, "_blank");
			await getJobs(EventType.CLICK, job.providerId, `${job.title} :: ((${job.company}))`);
		};

		pageListJobsJobList.innerHTML = '';
	} else if (jobs.length > 1) {
		pageListJobsJobList.innerHTML = '';
		for (let i = 0; i < jobs.length; i++) {
			const job = jobs[i];
			const jobCardTemplate = pageListJobsJobTemplate.cloneNode(true) as HTMLTemplateElement;
			const jobCard = jobCardTemplate.content.firstElementChild as HTMLElement;
			(jobCard.querySelector('.job__company') as HTMLParagraphElement).innerText = job.company;
			(jobCard.querySelector('.job__title') as HTMLParagraphElement).innerText = job.title;
			const description = job.description.replace(/\*/g, '');
			const jobCardDescription = jobCard.querySelector('.job__description') as HTMLParagraphElement;
			jobCardDescription.innerText = description;
			jobCardDescription.innerHTML += `&nbsp;<a href="${job.clickUrl}" target="_blank">Learn more.</a>`;
			(jobCard.querySelector('.job__learn-more') as HTMLButtonElement).onclick = async () => {
				window.open(job.clickUrl, "_blank");
				await getJobs(EventType.CLICK, job.providerId, `${job.title} :: ((${job.company}))`);
				hide(pageListJobs);
				showAndScrollToTop(pageJob);
			};

			const jobLogoImg = jobCard.querySelector('img.job__logo') as HTMLImageElement;
			if (job.logoUrl) {
				jobLogoImg.src = job.logoUrl;
				jobLogoImg.alt = `${job.company} logo`;
				show(jobLogoImg);
			} else {
				hide(jobLogoImg);
			}

			pageListJobsJobList.appendChild(jobCard);
		}
	}

	hide(pageLoading);
}

function hide(el: HTMLElement) {
	el.classList.add("hide");
}

function show(el: HTMLElement) {
	el.classList.remove("hide");
}

function showAndScrollToTop(el: HTMLElement) {
	show(el);
	scrollToTop();
}

function scrollToTop() {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
}

function hideAllPages() {
	hide(pageLanding);
	hide(pageRegister);
	hide(pageJobType);
	hide(pageJob);
	hide(pageListJobs);
	hide(pageLoading);
}

function getNumApplications(): number {
	const numApplications = localStorage.getItem("numApplications");
	if (!numApplications) {
		return 0;
	}

	return parseInt(numApplications);
}

function incrementNumApplications(): number {
	const numApplications = getNumApplications();
	const newNumApplications = numApplications + 1;
	localStorage.setItem("numApplications", newNumApplications.toString());
	return newNumApplications;
}

async function getJobsAndNavigate(event: EventType, eventProviderId: string | null, eventJobTitle: string | null) {
	if (environment === Environment.PRODUCTION) {
		checkMetaEvents(event);
  }

	hideAllPages();

	await getJobs(event, eventProviderId, eventJobTitle);

	if (jobs.length > 1) {
		showAndScrollToTop(pageListJobs);
	} else if (jobs.length === 1) {
		showAndScrollToTop(pageJob);
	} else {
		showAndScrollToTop(pageJobType);
		show(pageJobTypeError);
		pageJobTypeError.innerText = "No jobs found. Please try another keyword or zip code.";
	}
}

async function loadUrlParamsAndNavigate(): Promise<boolean> {
	let didNavigate = false;

	if (urlParams.has("firstName")) {
		searchData.firstName = urlParams.get("firstName")!;
		pageRegisterFirstNameInput.value = searchData.firstName;
	}
	if (urlParams.has("lastName")) {
		searchData.lastName = urlParams.get("lastName")!;
		pageRegisterLastNameInput.value = searchData.lastName;
	}
	if (urlParams.has("email")) {
		searchData.email = urlParams.get("email")!;
		pageRegisterEmailInput.value = searchData.email;
	}
	if (urlParams.has("keyword")) {
		searchData.keyword = urlParams.get("keyword")!;
		pageJobTypeKeywordInput.value = searchData.keyword;
	}
	if (urlParams.has("zipCode")) {
		searchData.zipCode = urlParams.get("zipCode")!;
		pageJobTypeZipCodeInput.value = searchData.zipCode;
	}

	if (searchData.firstName && searchData.email && searchData.keyword && searchData.zipCode) {
		await getJobsAndNavigate(EventType.CLICK, null, null);
		didNavigate = true;
	} else if (searchData.firstName && searchData.email) {
		hideAllPages();
		showAndScrollToTop(pageJobType);
		pageJobTypeH1.innerText = "Welcome!";
	}

	return didNavigate;
}

async function fireInternalLandEvent() {
	const params = new URLSearchParams({
		event: EventType.LAND,
		pageId: PageId.REGISER.toString(),
	}).toString();

	const response = await fetch(`${apiUrl}/events?${params}`, eventRequestInfo);
	const status = response.status
	if (status !== 200) {
		console.error("Failed to trigger landing event. Status: ", status);
		return;
	}
}

async function initSentry() {
	Sentry.init({
		dsn: "https://252c697501829983ce5e1cb1db552084@o4507267672440832.ingest.us.sentry.io/4507267830251520",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// TODO: Make this an environment variable.
		tracesSampleRate: 1.0,
		tracePropagationTargets: ["localhost", /^https:\/\/project-zebra-api-fh8yf.ondigitalocean\.app/],
		// Session Replay
		// TODO: Make this an environment variable.
		replaysSessionSampleRate: 0.1,
		// TODO: Make this an environment variable.
		replaysOnErrorSampleRate: 1.0,
	});
}

function checkMetaEvents(event: EventType) {
  if (event === EventType.CLICK) {
    const numApplications = incrementNumApplications();
    if (numApplications === numApplicationsForLead) {
      fireMetaLeadEvent();
    } else if (numApplications === numApplicationsForSubmitApplication) {
      fireMetaSubmitApplicationEvent();
    }
  }
}

function fireMetaPageViewEvent() {
	const fbq = (window as any).fbq as FBQ;
	fbq('track', 'PageView');
}

function fireMetaLeadEvent() {
	const fbq = (window as any).fbq as FBQ;
	fbq('track', 'Lead');
}

function fireMetaSubmitApplicationEvent() {
	const fbq = (window as any).fbq as FBQ;
	fbq('track', 'SubmitApplication');
}

function initMetaPixel() {
	const fbq = (window as any).fbq as FBQ;
	fbq('init', '1806610209843317');
}

////////////////////////////////////////////////////////////////////////////////
//                                   Main
////////////////////////////////////////////////////////////////////////////////

async function main() {
	if (environment === Environment.PRODUCTION) {
		initMetaPixel();
		fireMetaPageViewEvent();
		await initSentry();
	}
	const didNavigate = await loadUrlParamsAndNavigate()
	if (!didNavigate) await fireInternalLandEvent();
}

main().then();
